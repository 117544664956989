.faq-contact {

    .accordion {
        font-family: $font-family-base;
        color: $brand-copy;
        margin: 30px 0;

        .container {
            padding: 0px;

            h3 {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 25px;
                margin-top: 20px;
            }

            .margin-bottom {
                margin-bottom: 40px;
            }

            hr {
                width: 100%;
                border: 0;
                height: 0;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            }

            .card {
                margin: 0;
                padding-bottom: 40px;
            }

            a {
                color: $brand-name;
                font-size: 18px;
                font-weight: 500;
                text-decoration: underline;
            }

            .card-body {
                padding: 0;
                margin-bottom: 40px;

                p {
                    font-size: 18px;
                    color: #333333;
                }

                span {
                    color: $brand-name;
                    font-weight: 600;
                }
            }

            .card-header {
                background-color: #fff;
                border-bottom: 0;
                padding: 0;

                button {
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    text-align: left;
                    color: $brand-copy;
                    padding: 6px 20px 6px 0;
                    line-height: 20px;
                    font-size: 18px;
                    font-weight: 600;
                    width: 100%;
                    margin-bottom: 20px;

                    &:focus,
                    &:active {
                        box-shadow: none;

                        .arrow {
                            background-color: #eaeaea;
                        }
                    }

                    .arrow {
                        padding: 5px 5px 4px 5px;
                        border-radius: 50%;
                        margin-right: 20px;

                        .glyphicons-plus {
                            display: block;
                            -webkit-transition: -webkit-transform .2s linear;
                            transition: -webkit-transform .2s linear;
                            transition: transform .2s linear;
                            transition: transform .2s linear, -webkit-transform .2s linear;
                        }

                        path {
                            stroke: $faq-accordion-icon;
                        }

                    }
                }

                button[aria-expanded="true"] {
                    .arrow {
                        .glyphicons-plus {
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
    }
}
.project-ceprica .faq-contact {
    .accordion {
        .container {
            padding: 0;
        }
        .card {
            padding-bottom: 0;
        }
    }
}

@include media-breakpoint-up (md) {
    .project-ceprica .faq-contact {
        .accordion .card {
            padding-bottom: 40px;
        }
    }
}
