// NAV
// ***

.navbar-dark {
  background-color: $brand-app;
}

.navbar {
  top: 0px;
  padding: 0px 1rem;
  transition: padding-top 0.3s, margin-top 0.3s;

  .container {
    transition: margin-top 0.3s;
    margin-top: 33px;
    padding-bottom: 30px;
  }
}

.navbar-logo-outer {
  width: 150px
}

.navbar-logo {
  transition: width 0.3s, margin 0.3s;
  max-height: 70px;
  width: 100%;
  height: auto;
}

// this is the mobile hide mobile animation end result
.navbar.navbar-animate {
  padding-top: 0px;
  margin-top: -160px;

  .container {
    margin-top: 0;
  }
}


// this is a bootstrap class override
.navbar-nav {
  display: block;
  padding-left: 70px;

  a {
    color: $brand-nav-btn;
    text-decoration: underline;
    font-size: 13px;
  }
}

// htis is cusotm navigation text 
.navbar-btn-descr {
  display: block;
  width: auto;
  font-size: 13px;
  line-height: 13px;
  color: #fff;
}

// this is special for optimune project
.project-optimune {
  .navbar-logo {
    width: 150px;
  }

  .navbar.navbar-animate {
    padding-top: 5px;

    .navbar-logo {
      width: 120px;
    }
  }
}

// this is special for somnovia project
.project-somnovia {
  .navbar-logo {
    width: 170px;
  }

  .navbar.navbar-animate {
    padding-top: 5px;

    .navbar-logo {
      width: 150px;
    }
  }
}

// this is special for liebria project
.project-liebria .navbar-logo-outer img {
  width: 80%;
}

@include media-breakpoint-up(sm) {

  .navbar {
    .container {
      padding-bottom: 0px;
    }
  }

  .navbar-nav {
    display: flex;
    margin-bottom: 0px;
  }

  .navbar-btn-descr {
    font-size: 13px;
    display: inline-block;
  }

  .nav-item {
    a {
      font-size: 13px;
      padding-left: 5px;
    }
  }

  // this is the desktop shrink animation end result
  .navbar.navbar-animate {
    padding-top: 0px;
    margin-top: 0;

    .navbar-logo {
      width: 70%;
      margin: 5px 0;
    }

    .container {
      margin-top: 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .navbar-btn-descr {
    font-size: 17px;
    margin-right: 5px;
  }

  .nav-item {
    a {
      font-size: 17px;
    }
  }
}