//
// Variables Diff
// --------------------------------------------------


// Brand colors
// -------------------------

// General variables
$brand-app: #9A73A5;
$brand-sec: #f3e9f7; // used in passage devices
$brand-heading-dark: #390f44; // used for headings
$brand-custom-sec: $brand-heading-dark; // used for headings
$brand-name: #865e91; // color for "optimune" on light background
$brand-name-light: $brand-app; // color for "priovi" on dark background
$brand-copy: #3B1A3E;

$brand-custom: #4c145c; // used for buttons on white & borders of accordion
$brand-nav-btn: #340141;

$brand-icon: $brand-heading-dark; // used for icons
$brand-bg-sec: #e4fbff;

// navigation

// hero
$hero-btn: #fff;
$hero-box-bg: #390f44;
$hero-box-text: #fff;

// logo-card
$logo-card-bg: #fff;

// box
$box-container-bg: $brand-bg-sec;
$box-text: $brand-copy;
$box-bg: $brand-bg-sec;

// passage & form
$passage-bg: #fff;
$passage-box-bg: #390f44;
$passage-button-bg: #390f44;
$passage-button-label: #fff;
$brand-device-bg: $brand-sec; // used in passage devices

//carousel
$spot-heading: #9a73a5;

$brand-device-bg: $brand-sec; // used in passage devices

// testimonial-section
$testimonial-bg: #e6f5fb;
$testimonial-row-line: #fff;
$testimonial-text-color: #fff;
$testimonial-slider-bullet: #285056;

$testimonial-bg-light-theme: #67c3d5;
$testimonial-bg-light-play-button: #EBF5FD;
$testimonial-fill-light-play-button-icon: #335963;
$testimonial-divider-light-theme: #a9dae7;
$testimonial-name-light-theme: #285056;
$testimonial-light-theme-title-color: #285056;

$testimonial-bg-dark-theme: #2b5e79;
$testimonial-divider-dark-theme: #48737c;
$testimonial-name-dark-theme: #67c3d5;
$testimonial-dark-theme-title-color: #67c3d5;

// faq-conatct
$contact-icon-bg: $brand-name;
$faq-contact-bg: #fff;
$faq-contact-icon: #B097BB;
$faq-accordion-icon: $spot-heading;
$brand-custom: #e29ae4; // used for buttons on white & borders of accordion

// footer
$footer-bg: $brand-heading-dark;
$brand-name-footer: $brand-sec; // color for "optimune" on light background footer


// Scaffolding
// -------------------------

$subtext-color: #808080;


// Typography

$font-family-base: 'Jost',
sans-serif;
$font-family-jost: 'Jost',
sans-serif;



$font-size-base: 1.125rem; // 18px;
$font-size-lg: ($font-size-base * 1.2);
$font-size-sm: ($font-size-base * .86);

$h1-font-size: $font-size-base * 1.2;
$h2-font-size: $font-size-base * 1.1;
$h3-font-size: $font-size-base * 1;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base * 0.86;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.6; // 24/15 // 1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
// $line-height-computed:    floor($font-size-base * $line-height-base); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family: $font-family-base;
$headings-font-weight: 400; // 500;
$headings-line-height: 1.6; // 1.2;
$headings-color: inherit;


//nav dark theme

$navbar-dark-color: rgba($white, .8);
$navbar-dark-hover-color: rgba($white, 1);
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
$navbar-dark-toggler-border-color: rgba($white, 1);
$navbar-padding-y: 3px;




// Iconography
// -------------------------

$icon-font-path: "fonts/";
$icon-font-name: "glyphicons-regular"; // "glyphicons-halflings-regular";


// Components
// -------------------------
// Based on 14px font-size and 1.428 line-height (~20px to start)
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$line-height-large: 1.5; // 1.3333333; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5;

// border-radius
//@border-radius-base:        4px;
$border-radius-large: 6px;
//@border-radius-small:       3px;


// jumbotron

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

//margin for spacing

$margin-mobile: 25px;
$margin-tablet: 40px;
$margin-desktop: 60px;

@mixin space($amount) {
    margin: $amount 0;
}

//padding for within elements spacing

$padding-mobile: 25px;
$padding-tablet: 40px;
$padding-desktop: 40px;

@mixin inner-padding($amount, $sel:selector) {

    .#{$sel} {
        padding: 0;
    }

    .#{$sel}~.#{$sel} {
        padding: $amount 0 0 0;
    }
}



// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// Form 
$enable-validation-icons: false;

$grid-breakpoints: (xs: 0,
    phone: 480px, // as it was used in bootstrap 3 -> @screen-phone
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1500px)