.containing-form {
    padding-top: 60px;
    width: 100%;

    select {
        width: 200px;
    }

    .form-control {
        border-radius: 20px;
        padding: 0 20px;
    }
}

@include media-breakpoint-up (md) {
    .containing-form {
        padding-top: 10px;
    }
}