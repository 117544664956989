.jumbotron {
    width: 100%;
    margin-bottom: 0;
    padding-top: 80px;
    padding-bottom: 30px;
    background-color: $brand-app;

    .container {
        padding: 0 10px;

        .col-md-7 .col-md-5 {
            padding: 0;
        }
    }

    .content {
        color: #fff;
        margin: 45px auto 0 auto;

        h1 {
            font-size: 40px;
            line-height: 48px;
            font-weight: 700;
            font-family: $font-family-base;
            margin-bottom: 30px;
        }

        p {
            font-size: 17px;
            line-height: 26px;
            font-weight: 400;
            font-family: $font-family-base;
            margin-bottom: 30px;

            span {
                color: $brand-heading-dark;
                font-weight: 600;
            }
        }
    }

    .jumbotron-image {
        max-width: 100%;
        margin-top: 10px;

        object {
            width: 80%;
            display: block;
            margin: auto;
            padding-top: 20px;
        }

        img {
            width: 80%;
            display: block;
            margin: auto;
            padding-top: 20px;
        }

        p {
            font-size: 14px;
            text-align: right;
        }
    }
    .break {
        overflow-wrap: break-word;
       // hyphens: auto;
    }
}

@media only screen and (min-width : 370px) {

    .jumbotron {
        .container {
            padding: 0 10%;
        }
    }
}

@include media-breakpoint-up(sm) {
    .jumbotron {

        .container {
            padding: 0;
        }

        .jumbotron-image {
            margin-top: 10px;

            object {
                width: 60%;
            }

            img {
                width: 60%;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .jumbotron {

        .content {
            margin: 45px auto 0 auto;
        }

        .jumbotron-image {
            width: 350px;
            margin: auto;

            object {
                width: 100%;
                max-width: 100%;
                margin: auto;
                padding-top: 0;
                padding-left: 20px;
            }

            img {
                width: 100%;
                max-width: 100%;
                margin: auto;
                padding-top: 0;
                padding-left: 20px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .jumbotron {
        .content {

            h1 {
                font-size: 65px;
                line-height: 71px;
            }

            p {
                font-size: 20px;
            }
        }

        .jumbotron-image {
            max-width: 350px;
        }
    }
}